import * as React from 'react';
import { I18nContext } from 'app/common/I18n';
export default function Select({ options, ...rest }) {
  const { t } = React.useContext(I18nContext);
  return (
    <select {...rest}>
      {Object.keys(options).map((key) => (
        <option key={key} value={key}>
          {t(options[key])}
        </option>
      ))}
    </select>
  );
}
