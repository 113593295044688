
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Cookies og Web beacons`}</h1>
    <p>{`Akutbolig.dk anvender cookies til at gemme information om de besøgendes foretrukne sider, registrere brugerspecifikke oplysninger om de sider, brugeren besøger, gøre indholdet på hjemmesiden i overensstemmelse med den besøgendes browsertype eller anden information, som den besøgende sender via sin browser.`}</p>
    <p>{`DoubleClick DART Cookies`}</p>
    <ul>
      <li parentName="ul">{`Google, som tredjeparts sælger, anvender cookies til at vise annoncer på `}<a parentName="li" {...{
          "href": "http://www.Akutbolig.dk"
        }}>{`www.Akutbolig.dk`}</a>{`.`}</li>
      <li parentName="ul">{`Google’s brug af DART cookies gør det muligt for Google at vise annoncer til brugerne baseret på deres besøg på `}<a parentName="li" {...{
          "href": "http://www.Akutbolig.dk"
        }}>{`www.Akutbolig.dk`}</a>{` og andre websteder på internettet.`}</li>
      <li parentName="ul">{`Brugere kan framelde sig brugen af DART cookies ved at gå til Google’s annonce og indholdsnetværk’s politik om personlige oplysninger på den følgende adresse: `}<a parentName="li" {...{
          "href": "http://www.google.dk/privacy_ads.html"
        }}>{`http://www.google.dk/privacy_ads.html`}</a></li>
    </ul>
    <p>{`Nogle af vore annonce-partnere bruger måske cookies og web beacons på vor hjemmeside. Vore annonce-partnere omfatter:`}</p>
    <ul>
      <li parentName="ul">{`Adservice A/S`}</li>
      <li parentName="ul">{`Digital Advisor ApS`}</li>
      <li parentName="ul">{`EuroAds A/S`}</li>
      <li parentName="ul">{`Google Adsense`}</li>
      <li parentName="ul">{`Tradedoubler AB`}</li>
    </ul>
    <p>{`Disse tredjeparts annonce leverandører eller annonce netværk anvender teknologi til de annoncer og links, der vises på `}<a parentName="p" {...{
        "href": "http://www.Akutbolig.dk"
      }}>{`www.Akutbolig.dk`}</a>{`, der sendes direkte til din browser. De modtager automatisk din IP adresse, når dette sker. Andre teknologier (som f.eks. cookies, JavaScript eller Web Beacons) kan også anvendes af tredjeparts annoncenetværk til at måle effektiviteten af deres annoncer og / eller til at personalisere det annonceindhold, du ser.`}</p>
    <p>{`Akutbolig.dk har ikke adgang til, eller kontrol over, disse cookies, der bliver brugt af tredjeparts annoncører.`}</p>
    <p>{`Gennemlæs de respektive politikker vedrørende personlige oplysninger fra disse tredjeparts annonceleverandører for at få yderligere information om deres fremgangsmåder samt instruktion om, hvorledes du framelde dig bestemte metoder. Akutbolig.dk’s politik for personlige oplysninger gælder ikke for sådanne andre annoncører eller websteder og vi kan ikke kontrollere disse aktiviteter.
Hvis du ønsker at deaktivere cookies, gøres det gennem dine egne browser muligheder. Yderligere information om styringen af cookies med specifikke webbrowsere findes på de respektive browseres websites.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;