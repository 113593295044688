import * as React from 'react';
import DocumentMeta from 'react-document-meta';
import InspirationNavigation from 'app/common/InspirationNavigation';
import Markdown from './Markdown';

type Props = {
  meta: {
    title: string;
  };
  mdx?: React.ReactType;
};

type State = {
  mounted: boolean;
};

class Page extends React.Component<Props, State> {
  render() {
    const { meta, children, mdx } = this.props;

    return (
      <DocumentMeta title={meta.title} extend>
        <Markdown content={mdx}>{children}</Markdown>
        <InspirationNavigation columns={4} />
      </DocumentMeta>
    );
  }
}

export default Page;
