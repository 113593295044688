import * as React from 'react';
import { Link } from 'react-router-dom';
import { I18nContext } from 'app/common/I18n';
import * as styles from './InspirationNavigation.scss';

type Props = {
  nearby?: { name: string; slug: string }[];
  hideTypes?: boolean;
  columns?: 2 | 3 | 4;
  detailPage?: boolean;
};

type ItemShape = { label: string; to?: string; href?: string };
type SectionShape = ItemShape[];
type SectionsShape = SectionShape[];

function InspirationNavigation(props: Props) {
  const { t } = React.useContext(I18nContext);
  const sections: SectionsShape = [
    props.nearby?.length
      ? props.nearby.map((item) => ({
          label: t('Lejlighed %s', item.name),
          to: `/${item.slug}`
        }))
      : null,
    [
      { label: t('Lejlighed %s', 'København'), to: '/koebenhavn' },
      { label: t('Lejlighed %s', 'Aarhus'), to: '/aarhus' },
      { label: t('Lejlighed %s', 'Aalborg'), to: '/aalborg' },
      { label: t('Lejlighed %s', 'Odense'), to: '/odense' }
    ],
    props.hideTypes
      ? null
      : [
          { label: t('Hus til leje'), to: '/type/hus' },
          { label: t('Lejlighed til leje'), to: '/type/lejlighed' },
          { label: t('Værelse til leje'), to: '/type/vaerelse' },
          { label: t('Rækkehus til leje'), to: '/type/raekkehus' }
        ],
    [
      { label: t('Indflytning:') },
      { label: t('Lejeloven'), href: 'https://www.lejeloven.dk/' },
      {
        label: t('Find billigt internet'),
        href: 'https://speedtest.dk/billigt-internet'
      },
      {
        label: t('Indskudslån'),
        href: 'https://xn--indskudsln-95a.dk/'
      }
    ]
  ].filter(Boolean);

  return (
    <section
      className={[props.detailPage && styles.detailPage, styles.container]
        .filter(Boolean)
        .join(' ')}
    >
      <div>
        {sections.map((section, i) => {
          const classNames = [
            styles.links,
            section.length === 0 && styles.emptySection
          ]
            .filter(Boolean)
            .join(' ');
          return (
            <div
              key={i}
              className={classNames}
              style={{
                width: `${((1 / (props.columns || sections.length)) * 100)
                  .toFixed(4)
                  .replace(/\.?0+$/, '')}%`
              }}
            >
              {section.map(({ label, to, href }, j) => {
                return [
                  React.createElement(
                    to ? Link : href ? 'a' : 'span',
                    {
                      key: j,
                      // className: to || href ? styles.link : undefined,
                      to,
                      href,
                      target: href && '_blank',
                      rel: href && 'noopener'
                    },
                    label
                  ),
                  <br key={`${j}br`} />
                ];
              })}
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default React.memo(InspirationNavigation);
