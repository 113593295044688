import { useMutation } from 'app/labs/hooks/useMutation';
import React from 'react';
import { TextArea } from 'app/labs/components/fields/textarea';
import Button from 'app/labs/components/button';
import * as cardStyles from 'app/common/thread/Grid/imageCard.scss';

const SEND_MESSAGE = `
   mutation SendMessage($input: MessageInput!) {
       sendMessage(input: $input) {
              id
              to
              from
              emailTo
              externalTo
              emailFrom
              externalFrom
              thread
              text
              type
              createdAt
              updatedAt
         }
     }`;

const ChatTextBox = (props) => {
  const { t, refetch, afterClick, internalLink } = props || {};
  const [text, setText] = React.useState('');

  const { mutate } = useMutation(SEND_MESSAGE);

  const onChange = (e) => {
    setText(e.target.value);
  };

  const onClick = async () => {
    if (text.length === 0) {
      return;
    }
    const { variables } = props;

    const input = { ...variables, text };
    await mutate({ input });
    if (refetch) {
      refetch();
    }
    if (afterClick) {
      afterClick();
    }
  };

  return (
    <div style={{ display: 'flex', margin: '10px', marginTop: '15px' }}>
      <div
        className={cardStyles.left}
        style={{ width: '100%', paddingRight: '10px', display: 'grid' }}
      >
        <TextArea
          key="message"
          name="message"
          inputProps={{
            rows: 5
          }}
          placeholder={t('Write message')}
          onChange={onChange}
          style={{ width: '100%' }}
        />
      </div>
      <div
        className={cardStyles.right}
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'grid'
        }}
      >
        <Button
          type="submit"
          color="success"
          size="small"
          internalLink={internalLink}
          onClick={onClick}
          style={{ height: 'fit-content', width: 'max-content' }}
        >
          {t('Send message')}
        </Button>
      </div>
    </div>
  );
};

export default ChatTextBox;
