import { conversion, goal } from 'shared/tracking';

export default (
  tracking: string,
  { plan, conversionRef, email, phoneNumber }
) => {
  goal({
    name: plan.name,
    value: String(plan.oneoff / 100)
  });
  conversion({
    ref: conversionRef,
    // affiliation: '',
    product: {
      name: 'Boligsøgerlisten',
      code: plan.name,
      category: 'tenant',
      ltv: 300
    },
    user: {
      email,
      phoneNumber
    },
    revenue: plan.oneoff / 100,
    tax: (plan.oneoff * 0.2) / 100,
    fee: (Math.ceil(plan.oneoff * 0.0135) + 50) / 100,
    currency: plan.currency
  });
  goal(13103); // bsl_subscription
  goal(tracking);
};
