import * as React from 'react';
import * as styles from './box.scss';

function Box(props) {
  const { tag = 'div', children, className, ...rest } = props;
  const classes = [className, styles.container].join(' ');

  return React.createElement(tag, { ...rest, className: classes }, children);
}

export default Box;
