import { TLease, ListingFlag } from 'shared/types';
import { slugify } from 'lib/format';

export function hasFreeContact(lease: TLease) {
  return (
    lease.listingFlags?.includes(ListingFlag.FREE_CONTACT) ||
    ['PLUS', 'PREMIUM'].includes(lease.listingType)
  );
}

export const getHeight = (ref) => {
  const node = ref.current;
  if (node?.children) {
    return (node.children?.[0] as HTMLElement).offsetHeight;
  }
};

export const targetUrl = ({ id, city }: { id: string; city: string }) => {
  const area = slugify(city);
  return `/${area}/dq${id}`;
};

export function dateToText(date) {
  return date
    ? new Date(date)
        .toISOString()
        .replace('T', ' Kl.')
        .replace(/:[0-9]{2}\.[0-9]{3}Z/, '')
    : '-';
}
