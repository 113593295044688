import * as React from 'react';
import * as styles from '../fieldStyles.scss';
import { Label } from '../Label';

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  id?: string;
  className?: string;
  fullWidth?: boolean;
  endAdornment?: JSX.Element;
  onChange?: (ev: React.SyntheticEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  helperText?: { text: React.ReactNode; type: 'error' | 'warning' | null };
  style?: { [key: string]: string };
  inputProps?: {
    disabled?: boolean;
    ref?: React.RefObject<HTMLTextAreaElement>;
    defaultValue?: string | number;
    rows?: number;
  };
};

export const TextArea = (props: Props): JSX.Element => {
  const {
    disabled,
    inputProps,
    className,
    name,
    fullWidth,
    label,
    onChange,
    placeholder,
    readOnly,
    style
  } = props;
  return (
    <Label
      fullWidth={fullWidth}
      label={label}
      style={style || {}}
      className={`${styles.matterTextfieldOutlined} ${className || ''}`.trim()}
    >
      <textarea
        disabled={disabled}
        style={{ resize: 'none' }}
        className={styles.placeholderFix}
        id={props.id || name}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        readOnly={readOnly}
        {...inputProps}
      />
    </Label>
  );
};
