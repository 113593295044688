export const BOLIGAPI_GRAPH_ENDPOINT = '/api/boligapi-graph';

export const LEASES = `
query LeaseConnection($filter: LeaseFilter, $fill: [FillOption!], $disablePromotion: Boolean, $order: OrderType, $first: Int, $after: String, $last:Int, $before: String) {
  leaseConnection(filter: $filter, fill: $fill, disablePromotion: $disablePromotion, order: $order, first: $first, after: $after, last: $last, before: $before) {
    edges {
      cursor
      node {
        id
        fill
        snippet
        street
        zip
        city
        geo {
          coordinates
        }
        size
        rooms
        rent
        available
        typeDwelling
        images(limit: 1) {
          hash
        }
        listingType
        listingFlags
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalCount
    }
    nearbyAreas
  }
}
`;
